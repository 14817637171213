/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
/* import { Link } from 'react-router-dom';
*/
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import "../css/showcase.css";

function App() {

    return (<><section className="showcase">
        <div className="container-fluid p-0">
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 order-lg-2 text-white" style={{opacity: '1'}}>
                    <LazyLoadImage src="../assets/ventajas/principales-caracteristicas-de-nuestros-productos-en-carro.jpg" alt="principales-caracteristicas-de-nuestros-productos-en-carro" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white', opacity: 1}}>
                    <h2 style={{color: 'white'}}>PRINCIPALES CARACTERÍSTICAS DE NUESTROS PRODUCTOS EN CARROS</h2>
                    <p className="lead mb-0"><li>Remolque de estructura robusta y estable, con caja cerrada para Equipamiento.</li></p>
                    <p className="lead mb-0"><li>Mástil vertical de 6 metros, accionada por huinche manual con freno automático.</li></p>
                    <p className="lead mb-0"><li>Diseñados para trabajar en ambientes con temperaturas extremas y a gran altura.</li></p>
                    <p className="lead mb-0"><li>Realizamos diseños especiales, somos fabricantes.</li></p>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 text-white" style={{opacity: 1}}>
                <LazyLoadImage src="../assets/ventajas/aplicaciones.jpg" alt="aplicaciones" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white', opacity: 1}}>
                    <h2 style={{color: 'white'}}>ALGUNAS APLICACIONES</h2>
                    <p className="lead mb-0"><li>Seguridad y video vigilancia.</li></p>
                    <p className="lead mb-0"><li>Faenas mineras.</li></p>
                    <p className="lead mb-0"><li>Emergencias.</li></p>
                    <p className="lead mb-0"><li>Aparcamiento.</li></p>
                    <p className="lead mb-0"><li>Eventos y Ferias al aire libre.</li></p>
                    <p className="lead mb-0"><li>Deportes y campig.</li></p>
                    <p className="lead mb-0"><li>Constructoras.</li></p>
                    <p className="lead mb-0"><li>Alimentación de equipos VDC y VAC.</li></p>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 order-lg-2 text-white" style={{opacity: 1}}>
                <LazyLoadImage src="../assets/ventajas/ventajas-del-uso-de-energia-solar.jpg" alt="ventajas-del-uso-de-energia-solar" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white', opacity: 1}}>
                    <h2 style={{color: 'white'}}>VENTAJAS DEL USO DE ENERGÍA SOLAR</h2>
                    <p className="lead mb-0"><li>Es una energia autónoma, limpia y renovable.</li></p>
                    <p className="lead mb-0"><li>Sin costo de combustible.</li></p>
                    <p className="lead mb-0"><li>No emite ruidos como los motores a combustión.</li></p>
                    <p className="lead mb-0"><li>Los sistemas solares reducen los costos de mantención.</li></p>
                </div>
            </div>
        </div>
    </section></>);
}

export default App;