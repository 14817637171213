import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as Icon from 'react-bootstrap-icons';
import "../css/cotizador.css";
import emailjs from '@emailjs/browser';
/* import { Link } from "react-router-dom";  */
import Swal from 'sweetalert2';
import {useForm} from 'react-hook-form';

function FormCotizador() {
    const {register, formState:{errors},handleSubmit}= useForm();
    const [captchaIsDone, SetCaptchaDone] = useState(true);
    const publica = '6Lf6c5QpAAAAAF1GPnBYVUhsChjLftiCgMlrfP4b';
    const [cssData, setShow] = useState(false);


    const clicking = () => {
        setShow(!cssData)
    }

    const form2 = useRef();
const sendEmail = () => {
  emailjs
    .sendForm('service_hdespis', 'template_8sq888v', form2.current, {
      publicKey: 'zGjmkbCAr64lbsTe-',
    })
    .then(
      () => {
           console.log('ok');

        Swal.fire({
            position: "center",
            icon: "success",
            title: "Enviado",
            text: "Su Mensaje se envió",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6"/* ,
            timer: 3000 */
          }).then((result) => {
            if (result.isConfirmed) {
              form2.current.reset();
            }
          });
          
      },
      (error) => {
        console.log('error:', error.text);
        Swal.fire({
            title: 'Error!',
            text: error.text,
            icon: 'error',
            confirmButtonText: 'Cool'
          })
      }
    );
  }

  const eliminaTildes = (e)  => {
    e.preventDefault();
    var titulo = e.target.value.replace("á", 'a').replace("é", 'e').replace("í", 'i').replace("ó", 'o').replace("ú", 'u').replace("ü", 'u').replace("Á", 'A').replace("É", 'E').replace("Í", 'I').replace("Ó", 'O').replace("Ú", 'U').replace("Ü", 'U');
    e.target.value = titulo;
  }


  useEffect( ()=> {
    if(window.grecaptcha){
      SetCaptchaDone(true);
    }else{
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
      script.async = true;
      script.id = 'rc';
      document.head.appendChild(script);
      script.onload = () => {SetCaptchaDone(true)}
    }
    }, [publica])
    
    const executeRecaptcha = useCallback(async (action)=>{
      if(captchaIsDone && window.grecaptcha){
    return await window.grecaptcha.execute(publica, {action})
      }else{
        return executeRecaptcha;
      }
    })

    return (<><a className={cssData ? 'contact-btn active' : 'contact-btn'}>
        <button className="contact-bg" onClick={clicking}>Cotizador</button>
    </a>
        <div className="contact-form text-black" style={{ right: cssData ? "0px" : "-450px" }}>
            <button className="close-btn text-capitalize text-end" onClick={clicking}><Icon.XSquareFill></Icon.XSquareFill></button>
            <h2 className="title mb-5">Solicitar <span> Presupuesto </span></h2>
            <form id="form"  ref={form2} onSubmit={handleSubmit(()=>{sendEmail();})}>
            <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_name">Nombre</label>
                    <input type="text" {...register("user_name",{required: true, minLength: 3, maxLength:40})} onChange={eliminaTildes}
                    id="user_name" placeholder="Nombre..." className="contact-nombre form-control" autoComplete='name' name="user_name"/>
                    {
                      errors.user_name?.type==="required" && (
                          <p style={{color:'green'}}>¡Su Nombre es requerido!</p>
                      )
                    }
                    {
                      errors.user_name?.type==="minLength" && (
                          <p style={{color:'green'}}>Nombre son mínimo 3 caracteres!</p>
                      )
                    }
                    {
                      errors.user_name?.type==="maxLength" && (
                          <p style={{color:'green'}}>Nombre largo máximo de 40 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_email">Email</label>
                    <input type="text" {...register("user_email",{pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i, required: true, minLength: 5, maxLength:40})}
                    id="user_email" placeholder="Email..." className="contact-email form-control" autoComplete='email'/>
                    {
                      errors.user_email?.type==="pattern" && (
                          <p style={{color:'green'}}>¡Formato del Email no válido!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="required" && (
                          <p style={{color:'green'}}>¡Email es requerido!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="minLength" && (
                          <p style={{color:'green'}}>¡Email son mínimo 5 caracteres!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="maxLength" && (
                          <p style={{color:'green'}}>¡Email largo máximo de 40 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_email">Teléfono</label>
                    <input type="tel" {...register("message",{required: true, minLength: 9, maxLength:12,})}
                    id="user_telefono" placeholder="Se requiere teléfono" className="contact-email form-control" pattern="[0-9]{9}"/>
                    {
                      errors.user_email?.type==="required" && (
                          <p style={{color:'green'}}>Teléfono es requerido!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="minLength" && (
                          <p style={{color:'green'}}>Teléfono son mínimo 9 caracteres!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="maxLength" && (
                          <p style={{color:'green'}}>¡Teléfono largo máximo de 12 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <select id="prod_serv" name="prod_serv" {...register("message",{required: true})} defaultValue="" title="Debe seleccionar un Producto o Servicio" className="form-control" data-error="Se requiere seleccionar un Producto o Servicio" style={{ marginBottom: '12px' }}>
                        <option value="">Seleccione un Producto o Servicio</option>
                        <option value="Lámpara de Emergencia">Lámpara de Emergencia</option>
                        <option value="Torre de Vigilancia Solar">Torre de Vigilancia Solar</option>
                        <option value="Torre de Iluminación Solar">Torre de Iluminación Solar</option>
                        <option value="Semáforo Solar">Semáforo Solar</option>
                        <option value="Generador Fotovoltaico">Generador Fotovoltaico</option>
                        <option value="Generación Residencial">Generación Residencial</option>
                        <option value="Telemetría">Telemetría</option>
                    </select>
                    {
                      errors.user_email?.type==="required" && (
                          <p style={{color:'green'}}>Producto o Servicio es requerido!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="message">Mensaje</label>
                    <textarea  {...register("message",{required: true, minLength: 3, maxLength:200,})}
                    id="message" placeholder="Mensaje..." className="contact-message form-control" ></textarea>
                    {
                      errors.message?.type==="required" && (
                          <p style={{color:'green'}}>¡Un Mensaje es requerido!</p>
                      )
                    }
                    {
                      errors.message?.type==="minLength" && (
                          <p style={{color:'green'}}>¡Mensaje son mínimo 3 caracteres!</p>
                      )
                    }
                    {
                      errors.message?.type==="maxLength" && (
                          <p style={{color:'green'}}>¡Mensaje largo máximo de 200 caracteres!</p>
                      )
                    }
                </div>
               <div className="form-bottom">
                    {captchaIsDone && <button type="submit" className="btn btn-outline-success">Enviar Mensaje</button>}
                </div>
            </form>
        </div></>);
}

export default FormCotizador;