/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
function App() {

    return (<><div className="container-fluid" style={{justifyContent: 'center', alignItems: 'center'}}>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Bateria-Gel-12v.jpg" alt="Bateria-Gel-12v" className="gallary-img" />
                <Link className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Bateria Gel 12v</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-fotovoltaica.jpg" alt="Torre-fotovoltaica" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Fotovoltaica</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-iluminacion.jpg" alt="Torre-iluminacion" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Iluminación</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-solar-2p.jpg" alt="Torre-solar-2p" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Solar 2 Paneles</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Camara-IP-Wiper.jpg" alt="Camara-IP-Wiper" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Camara IP Wiper</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Fotovoltaico-8k.jpg" alt="Fotovoltaico-8k" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Fotovoltaico 8k</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/sistema-solar-autonomo-litueche.jpg" alt="sistema-solar-autonomo-litueche" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Sistema solar autónomo</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Kit-Solar-Camara-12v.jpg" alt="Kit-Solar-Camara-12v" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Kit Solar Camara 12v</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Central-Solar-6p.jpg" alt="Central-Solar-6p" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Central Solar 6 Paneles</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/semaforo-Solar2.jpg" alt="semaforo-Solar2" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Semáforo Solar 2 Paneles</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/paneles-fotovoltaicos-parcela-region-16-nuble.jpg" alt="paneles-fotovoltaicos-parcela-region-16-nuble" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Paneles Fotovoltaicos</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Carro-Generacion-Solar-4p.jpg" alt="Carro-Generacion-Solar-4p" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Carro Solar 4 Paneles</p>
                </Link>
            </div>
        </div></div></>);
}

export default App;
