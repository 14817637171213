

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import "../css/all.min.css";
import "../lib/animate/animate.min.css";

/* 
import "../css/style.css";
import "../lib/owlcarousel/assets/owl.carousel.css";
 */

function App(props) {

    return (<><div className="container-fluid pb-5">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="pr-3" style={{ color: 'white', paddingLeft: '10px' }}>Producto</span></h2>
        <div className="row px-xl-5">
            <div className="col-lg-5 mb-30">
                <div id="product-carousel" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner bg-light">
                        <div className="carousel-item active">
                            <LazyLoadImage className="w-100 h-100" src={`${props.img}`} alt="" />
                        </div>
                        <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={`${props.img2}`} alt="" />
                        </div>
                    </div>
                    <Link className="carousel-control-prev" data-target="#product-carousel" data-slide="prev">
                        <i className="fa fa-2x fa-angle-left text-dark"></i>
                    </Link>
                    <Link className="carousel-control-next" data-target="#product-carousel" data-slide="next">
                        <i className="fa fa-2x fa-angle-right text-dark"></i>
                    </Link>
                </div>
            </div>

            <div className="col-lg-7 h-auto mb-30">
                <div className="h-100 bg-light p-30">
                    <h3>{props.titulo}</h3>
                    <div className="d-flex mb-3">
                        <div className="text-primary mr-2">

                        </div>

                    </div>
                  {/*   <h3 className="font-weight-semi-bold mb-4"></h3> */}
                    <p className="mb-4"></p>
                    <div className="d-flex mb-3">
                        {props.titulo2}
                    </div>
                    <div className="d-flex mb-4">
                        {props.titulo3}
                    </div>
                    <div className="d-flex align-items-center mb-4 pt-2" >
                        <div className="input-group quantity mr-3" style={{ width: '130px' }}>
                            <div className="input-group-btn">

                            </div>

                            <div className="input-group-btn">

                            </div>
                        </div>

                    </div>
                    <div className="d-flex pt-2">
                       
                    </div>
                    <div className="d-flex pt-2">
                     
                    </div>
                    <div className="d-flex pt-2">
                     
                    </div>
                </div>
            </div>
        </div>
        <div className="row px-xl-5">
            <div className="col">
                <div className="bg-light p-30">
                    <div className="nav nav-tabs mb-4">
                        <Link className="nav-item nav-link text-dark2 active" to="">Descripción</Link>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="tab-pane-1">
                            <h4 className="mb-3" style={{ color: 'black' }}>{props.sub1}</h4>
                            <p></p>
                            <p><b>LUCES SEMÁFORO:</b> {props.lucessemaforo}</p>
                            <p><b>ENCENDIDO LUCES:</b> {props.encendidoluces}</p>
                            <p><b>PANELES:</b> {props.paneles}</p>
                            <p><b>BATERIAS:</b> {props.baterias}</p>
                            <p><b>CONTROLADOR:</b> {props.controlador}</p>
                            <p><b>CIRCUITO</b></p>
                            <p><li>{props.circuito}</li></p>
                            <p><li>{props.circuito2}</li></p>
                            <p><li>{props.circuito3}</li></p>
                            <p></p>
                            <p><b>CAJA EQUIPOS:</b> {props.cajaequipos}</p>
                            <p><b>ACCESO:</b> {props.acceso}</p>
                            <p><b>TORRE:</b> {props.torre}</p>
                            <p><b>DESPLAZAMIENTO:</b> {props.desplazamiento}</p>
                            <p><b>TERMINACIÓN:</b> {props.terminacion}</p>
                            <p><b>DIMENSIONES:</b> {props.dimensiones}</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>)
}

export default App;
