/* import logo from './logo.svg'; */
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Portada from "./pages/portada.js";
import Productos from "./pages/productos.js";
import Ventajas from "./pages/ventajas.js";
import Navbar from './components/Navbar2.js';
import Cotizador from './components/cotizador.js';
import Contacto from './pages/contacto.js';
import Somos from './pages/portada.js';
import Footer from './components/footer.js';
import Ficha from './pages/ficha.js';
import Ficha2 from './pages/ficha2.js';
import Ficha3 from './pages/ficha3.js';
function App() {
  return (
     <>
    <BrowserRouter>
      <Navbar></Navbar>

      <Routes>
      <Route path="/" element={<Portada />} />
      <Route path="/productos" element={<Productos />} />
      <Route path="/productos/torre-carro-solar-de-vigilancia-y-seguridad-2-paneles" element={<Ficha />} />
      <Route path="/productos/torre-carro-solar-de-iluminacion-led-2-paneles" element={<Ficha2 />} />
      <Route path="/productos/carro-semaforo-solar-control-vial-remoto-telemetria" element={<Ficha3 />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/energia-solar/contacto/" element={<Contacto />} />
      <Route path="/" element={<Somos />} />
      <Route path="/ventajas-y-caracteristicas" element={<Ventajas />} />
      <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
      <Footer />
      <Cotizador></Cotizador>
      </BrowserRouter>
    </>
  );
}

export default App;
