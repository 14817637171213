/* import React, { useState } from 'react'; */
import Ventajas from '../components/ventajas.js';
import Titulo from '../components/titulo_central.js';
import Video from '../components/video.js';
function App() {
    return (
        <>
   

<div ><Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} /></div>
<div> <Titulo titulo="ENERGÍA MOVIL Y AUTÓNOMA"/><Ventajas />
            <Titulo titulo="SIN EMICIONES DE CARBONO, NI COSTOS EN COMBUSTIBLES"/></div>
        </>
    );
}

export default App;