/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
/* import { Link } from 'react-router-dom';
*/
/* import { LazyLoadImage } from 'react-lazy-load-image-component';  color: '#212529'*/
import "../css/showcase.css";

function App(props) {

    return (<div id="" className="text-center text-light has-height-md middle-items" style={{backgroundColor: 'transparent',  position:'relative'}}>
        <h2 className="" style={{color: 'white', opacity: 1.0, fontSize: props.size, textShadow: '-1px 0 #000, 0 2px #000, 2px 0 #000, 0 -1px #000', fontFamily: "'Oswald', sans-serif"}}>{props.titulo}</h2>
    </div>);
}

export default App;