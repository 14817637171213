/* import React, { useState } from 'react'; */
/* import Ventajas from '../components/ventajas.js'; */
import Galeria from '../components/galeria_recuadros2.js';
import Titulo from '../components/titulo_central.js';
import Video from '../components/video.js';
import Formulario from '../components/formulario.js';
function App() {
    return (<>
<div >
    <Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} />
</div>
<div >
    <Titulo titulo="REALIZAMOS DISEÑOS ESPECIALES, SOMOS FABRICANTES"/>
    <Formulario />
    <Galeria />
    <Titulo titulo="NUESTROS PRODUCTOS NO EMITEN RUIDO DE MOTOR"/></div>
</>);
}

export default App;