/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
function App() {

    return (<><div className="container-fluid" style={{justifyContent: 'center', alignItems: 'center'}}>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-de-emergencia.jpg" alt="Iluminacion-de-emergencia" className="gallary-img" />
                <Link className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Iluminación de emergencia</p>
                </Link>
            </div>
           <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/sistema-hogar-fotovoltaico-5000w.jpg" alt="sistema-hogar-fotovoltaico-5000w" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Sistema fotovoltaico 5000w</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-salida-show-explanada-penalolen-enero-2020.jpg" alt="Iluminacion-salida-show-explanada-penalolen-enero-2020" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Iluminación salida show</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-de-obra.jpg" alt="Iluminacion-de-obra" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Iluminación de obra</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/desmontar-escenario.jpg" alt="desmontar-escenario" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Desmontar escenario</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-de-evento.jpg" alt="Iluminacion-de-evento" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Iluminación de evento</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-sector-comidas.jpg" alt="Iluminacion-sector-comidas" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Iluminación sector comidas</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/evento-nocturno-lippi.jpg" alt="evento-nocturno-lippi" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Evento nocturno Lippi</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Iluminacion-de-fondas.jpg" alt="Iluminacion-de-fondas" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Iluminación de fondas</p>
                </Link>
            </div> <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-fotovoltaica.jpg" alt="Torre-fotovoltaica" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Fotovoltaica</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-iluminacion.jpg" alt="Torre-iluminacion" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Iluminación</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/galeria/Torre-solar-2p.jpg" alt="Torre-solar-2p" className="gallary-img" />
                <Link to="" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Torre Solar 2 Paneles</p>
                </Link>
            </div>
            
        </div></div></>);
}

export default App;
