

import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import "../css/footer.css";

function App() {
    return (<>
  <footer className="d-flex flex-wrap justify-content-between py-3 my-4">
    <div className="col-md-12 d-flex align-items-center">
      <ul className="nav col-md-6">
        <li className="ms-3"><Link className="text-decoration-none" to="https://www.applite.cl/" target="_blank" style={{color: 'white'}}>© applite.cl</Link></li>
      </ul>
      <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
        <li className="ms-3" ><Link className="text-body-secondary" to="mailto:ventas@carrosolar.cl" ><Icon.Envelope style={{color: 'white'}}></Icon.Envelope></Link></li>
        <li className="ms-3"><Link className="text-body-secondary" to="tel:+56996806045"><Icon.Telephone style={{color: 'white'}}></Icon.Telephone></Link></li>
        <li className="ms-3"><Link className="text-body-secondary" to="tel:+56996806045"><Icon.Whatsapp style={{color: 'white'}}></Icon.Whatsapp></Link></li>
        <li className="ms-3"><Link className="text-body-secondary" to="https://www.instagram.com/vajequipos" target="_blank"><Icon.Instagram style={{color: 'white'}}></Icon.Instagram></Link></li>
      </ul>
    </div>
  </footer>
</>);
}

export default App;