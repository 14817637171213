/* import React, { useState } from 'react'; */
import Galeria from '../components/galeria_recuadros.js';
import DetalleProducto from '../components/detalle_producto.js';
import Titulo from '../components/titulo_central.js';
import Video from '../components/video.js';
function App() {
    return (
        <>
   

<div ><Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} /></div>
<div> <Titulo titulo="PRODUCTOS AMIGABLES CON LA NATURALEZA" size="42px"/><DetalleProducto />
            <Galeria /><Titulo titulo="PRODUCTOS AUTOSUSTENTABLES" size="42px"/></div>
        </>
    );
}

export default App;