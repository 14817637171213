/* import '../css/style.css';
import '../App.css'; */
import Video from '../components/video.js';
import PDetalle from '../components/productoDetalle3.js';
import Breadcrumb from '../components/breadcrumb.js';
import "../css/ficha.css";

function App() {

        return (
                <>
                        <Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} />
                        <div style={{ paddingTop: '60px' }}><Breadcrumb categoria='Productos' descripcion='CARRO SEMÁFORO SOLAR CONTROL REMOTO' url='/productos'></Breadcrumb>
                                <PDetalle
                                        titulo="CARRO SEMÁFORO SOLAR CONTROL REMOTO"
                                        titulo2="TELEMETRÍA"
                                        titulo3="VERDE Y ROJO 200mm"
                                        lucessemaforo ="2 módulos led Verde y Roja en 12V 200mm."
                                        encendidoluces ="Por Control Remoto."
                                        paneles="1 Módulos fotovoltaicos 50 Watt Policristalino 12v."
                                        baterias="1 unidades Gel ciclo profundo 35A 12V."
                                        controlador ="Regulador Carga PWM 12V."
                                        circuito="Automático DC paneles y baterías."
                                        circuito2="Conectores y cables de baterías y paneles."
                                        circuito3="Cable eléctrico para focos."
                                        cajaequipos="Porta paneles, equipamiento y baterías en plancha de acero."
                                        acceso="Marco de Panel solar con pomeles."
                                        torre="2.2 a 2.5 metros, abatible para el traslado."
                                        desplazamiento="Tipo carretilla con ruedas frontales, topes y manillar."
                                        terminacion="Con imprimante y  pintura poliuretano color Naranjo."
                                        dimensiones="Largo 1000mm x 550mm ancho y 1400 a 2200mm alto."
                                        img="../assets/galeria/carro-semaforo-solar-control-remoto-1.jpg"
                                        img2="../assets/galeria/carro-semaforo-solar-control-remoto-2.jpg"
                                />
                        </div></>
        );
}

export default App;