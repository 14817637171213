/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
/* import { Link } from 'react-router-dom';
*/
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import { Link } from 'react-router-dom';
import "../css/showcase.css";

function App() {

    return (<><section className="showcase">
        <div className="container-fluid p-0">
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 order-lg-2 text-white" style={{opacity: '1'}}>
                    <LazyLoadImage src="../assets/productos/torre-de-vigilancia-y-seguridad-solar.jpg" alt="torre-de-vigilancia-y-seguridad-solar" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white'}}>
                    <h2 style={{color: 'white'}}>TORRE SOLAR DE VIGILANCIA Y SEGURIDAD</h2>
                    <p className="lead mb-0"><li>Cámaras IP de alta definición, visión nocturna y PTZ.</li></p>
                    <p className="lead mb-0"><li>Cámaras Termales Bi-espectral con PTZ.</li></p>
                    <p className="lead mb-0"><li>Inteligencia Artificial de Lectura de Patentes.</li></p>
                    <p className="lead mb-0"><li>Cámaras con limpia parabrisas para libre mantención.</li></p>
                    <p className="lead mb-0"><li>Enlace de Internet 4G o STARLINK.</li></p>
                    <p className="lead mb-0"><li>Monitoreo remoto por App móvil y computadora.</li></p>
                    <p className="lead mb-0"><li>Detección perimetral de barreras microondas con antena.</li></p><br></br>
                    <Link className="btn btn-outline-light" to="/productos/torre-carro-solar-de-vigilancia-y-seguridad-2-paneles">
                      <span>Ver Ficha</span>
                    </Link>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 text-white" style={{opacity: 1}}>
                <LazyLoadImage src="../assets/productos/torre-de-iluminacion-solar.jpg" alt="torre-de-iluminacion-solar" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white', opacity: 1}}>
                    <h2 style={{color: 'white'}}>TORRE SOLAR PARA ILUMINACIÓN</h2>
                    <p className="lead mb-0"><li>Tecnología LED de alta eficiencia con 180Lm por Watts.</li></p>
                    <p className="lead mb-0"><li>Alternativa de luz blanca y cálida DS43.</li></p>
                    <p className="lead mb-0"><li>Sistema eléctrico trabaja en 24v o 48vcc.</li></p>
                    <p className="lead mb-0"><li>Programación de encendido por Horario.</li></p>
                    <p className="lead mb-0"><li>Trabaja en forma autónoma 12 horas diarias.</li></p><br></br>
                    <Link className="btn btn-outline-light" to="/productos/torre-carro-solar-de-iluminacion-led-2-paneles">
                      <span>Ver Ficha</span>
                    </Link>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 order-lg-2 text-white" style={{opacity: 1}}>
                <LazyLoadImage src="../assets/productos/carro-solar-control-vial-telemetria.jpg" alt="carro-solar-control-vial-telemetria" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white'}}>
                    <h2 style={{color: 'white'}}>CARRO SEMÁFORO SOLAR CONTROL VIAL REMOTO - TELEMETRÍA</h2>
                    <p className="lead mb-0"><li>Semáforo Solar Vial, operado por control remoto.</li></p>
                    <p className="lead mb-0"><li>Carro Solar Camara IP bajo consumo.</li></p>
                    <p className="lead mb-0"><li>Carro Solar Internet wifi Router 4G.</li></p>
                    <p className="lead mb-0"><li>Sistemas de medición de Humedad del aire, temperatura, velocidad de viento, Vibración.</li></p><br></br>
                    <Link className="btn btn-outline-light" to="/productos/carro-semaforo-solar-control-vial-remoto-telemetria">
                      <span>Ver Ficha</span>
                    </Link>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 text-white" style={{opacity: 1}}>
                    <LazyLoadImage src="../assets/productos/poste-energia-solar.jpg" alt="poste-energia-solar" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white'}}>
                    <h2 style={{color: 'white'}}>POSTE ENERGIA SOLAR</h2>
                    <p className="lead mb-0"><li>Cámaras IP de alta definición y/o iluminación Led alta Eficiencia.</li></p>
                    <p className="lead mb-0"><li>Extructura galvanizada o Zincada.</li></p>
                    <p className="lead mb-0"><li>Tablero de metal IP66 con placa montaje.</li></p><br></br>
                    <button className="btn btn-outline-light"><span>Ver Ficha</span></button>
                </div>
            </div>
            <div className="row g-0" style={{position: 'relative', backgroundColor: '#3d713f', opacity: 0.8}}>
                <div className="col-lg-6 order-lg-2 text-white" style={{opacity: 1}}>
                <LazyLoadImage src="../assets/productos/ups-baterias-gel-litio.jpg" alt="ups-baterias-gel-litio" style={{ width: '100%', height: 'auto'}}/>
                </div>
                <div className="col-lg-6 order-lg-1 my-auto showcase-text" style={{ backgroundColor: '#3d713f', color: 'white'}}>
                    <h2 style={{color: 'white'}}>UPS - BATERIAS GEL / LITIO</h2>
                    <p className="lead mb-0"><li>Cámaras IP de alta definición y/o iluminación Led alta Eficiencia.</li></p>
                    <p className="lead mb-0"><li>Extrurtura galvanizada o Zincada.</li></p>
                    <p className="lead mb-0"><li>Tablero de metal IP66 con placa montaje.</li></p><br></br>
   {/*                  <button className="btn btn-outline-light"><span>Ver Ficha</span></button> */}
                </div>
            </div>
        </div>
    </section></>);
}

export default App;