/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
/* 
*/
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import "../css/Navbar2.css";
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
/* import "../css/bootstrap.min.css";
 */
function App() {

    return (<><div id="header-wrap" style={{zIndex: '999', position: 'relative'}}>
        <div className="container" style={{padding: 0, margin: 0}}>
           <div className="row">
              <div className="col">
                 <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand logo" to="/">
                    <LazyLoadImage key="logo-img" className="img-fluid" src="../assets/logo/carrosolar.png" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarNavDropdown">
                       <ul className="nav navbar-nav ms-auto">
                          <li className="nav-item dropdown"> <Link className="nav-link" to="/">Inicio</Link>
                          </li>
   {/*                        <li className="nav-item dropdown"> <Link className="nav-link" to="/">Quienes Somos</Link>
                          </li> */}
                          <li className="nav-item dropdown"> <Link className="nav-link" to="/productos">Productos</Link>
                          </li>
                          <li className="nav-item dropdown"> <Link className="nav-link" to="/ventajas-y-caracteristicas">Ventajas y Características</Link>
                          </li>
                          <li className="nav-item dropdown"> <Link className="nav-link" to="/contacto">Contacto</Link>
                          </li>
                       </ul>
                    </div>
                 </nav>
              </div>
           </div>
        </div>
     </div></>);
}

export default App;