/* import React, { useState } from 'react'; */
import Galeria from '../components/galeria_recuadros2.js';
import Ventajas from '../components/ventajas.js';
import Titulo from '../components/titulo_central.js';
import Video from '../components/video.js';
function App() {
    return (
        <>
   

<div ><Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} /></div>
<div style={{paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px'}}>
    <Titulo titulo="Nuestra misión es impulsar las energías de origen renovables a través de Carros de arrastres, 
    equipados con sistemas fotovoltaicos que ayudan a reducir el impácto ecológico y la huella de carbón, 
    así generar conciencia ecológica y respeto por el medio ambiente." size="28px"/>
</div ><div ><Ventajas />      <Titulo titulo="ENERGÍA MOVIL Y AUTÓNOMA" size="42px"/>
            <Galeria /><Titulo titulo="SIN EMICIONES DE CARBONO, NI COSTOS EN COMBUSTIBLES" size="42px"/></div>
        </>
    );
}

export default App;