/* import '../css/style.css';
import '../App.css'; */
import Video from '../components/video.js';
import PDetalle from '../components/productoDetalle.js';
import Breadcrumb from '../components/breadcrumb.js';
import "../css/ficha.css";

function App() {

        return (
                <>
                        <Video nombre="../assets/video/carrosolar.mp4" caption="productos" replay={true} string={<div></div>} />
                        <div style={{ paddingTop: '60px' }}><Breadcrumb categoria='Productos' descripcion='TORRE CARRO SOLAR PARA ILUMINACIÓN' url='/productos'></Breadcrumb>
                                <PDetalle
                                        titulo="TORRE CARRO SOLAR PARA ILUMINACIÓN"
                                        titulo2="CARRO SOLAR ILUMINACION LED"
                                        titulo3="43.200 a 108.000 lúmenes"
                                        sub1="Sistema Fotovoltaico"
                                        paneles="Opciones en 2 - 3 - 4 módulos Fotovoltaicos 160 a 400 Watt."
                                        baterias="De 2 a 4 unidades ciclo profundo 100 -200Ah Gel o Litio."
                                        controlador="Regulador de Carga MPPT."
                                        circuito="Automático DC paneles y baterías."
                                        circuito2="Tablero automáticos."
                                        circuito3="Conectores y cables."
                                        sub2="Luminaria LED"
                                        focos="2 a 5 unidades led SMD 43.200 a 108.000 lúmenes"
                                        potencia="Opciones en 240w  - 480w -  600W"
                                        eficiencia="180 lm/w"
                                        proteccion="IP66"
                                        disipador="Aluminio AL6063-T5."
                                        sub3="Carro Arrastre con Torre de 6 metros"
                                        dimensiones="Superficie total con lanza 2,7m largo x 1,8m ancho x 2,5m alto."
                                        cajaconpuerta="Porta paneles, equipamiento y baterías revestida en plancha de acero galvanizada."
                                        torre="Elevación vertical de 6 metros galvanizada."
                                        izaje="Winche manual con piola de acero y freno automático."
                                        estabilizadores="2 tirantes para tensar torre."
                                        chasis="Perfil rectangular de acero  50/50/3mm."
                                        suspension="Un eje galvanizado con suspensión de barra de torsión para  500 Kg."
                                        llantas="Aro 13 galvanizadas importadas con neumático 175/70/R13."
                                        tapabarros="Negros de plástico."
                                        piso="En acero diamantado 2,5mm de espesor."
                                        luces="Juego Led norma DIN con enchufe de conexión blindado de 7 polos."
                                        enganche="Mano galvanizada de 1 7/8” con dos cadenas de seguridad y dos grilletes."
                                        terminacion="Chasis con imprimante y pintura poliuretano color gris."
                                        terminacion2="3 patas apoyo regulables con manivela."
                                        sub4="Opcionales"
                                        opcionales="Rueda de maniobra regulable y abatible."
                                        opcionales2="Rueda de repuesto completa e instalada."
                                        opcionales3="2 Cuñas para ruedas con porta cuña."
                                        opcionales4="Enganche de argolla tipo agrícola."
                                        img="../assets/galeria/torre-carro-solar-de-vigilancia-y-seguridad-1.jpg"
                                        img2="../assets/galeria/torre-carro-solar-de-vigilancia-y-seguridad-camara-ip-wiper.jpg"
                                        img3="../assets/galeria/torre-carro-solar-de-vigilancia-y-seguridad-starlink.jpg"
                                        img4="../assets/galeria/torre-carro-solar-de-vigilancia-y-seguridad-termal-biespectral.jpg"
                                />
                        </div></>
        );
}

export default App;